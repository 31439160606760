<template>
  <v-row>
    <v-col cols="2" class="subtitle-1 mt-2">Email</v-col>
    <v-col cols="10">
      <v-text-field
        v-model="form.username"
        dense
        data-test="username"
        outlined
        :rules="[rules.required, rules.email]"
      />
    </v-col>
    <v-col cols="2" class="subtitle-1 mt-2" v-if="groups">Allowed roles</v-col>
    <v-col cols="10" v-if="groups">
      <v-autocomplete
        multiple
        v-model="form.groupIds"
        :items="groups"
        dense
        id="group-select"
        data-test="groupIds"
        chips
        small-chips
        :rules="[rules.minOne]"
        clearable
        item-text="name"
        item-value="id"
        outlined
      ></v-autocomplete>
    </v-col>
  </v-row>
</template>

<script>
import { queries } from "@/gql";

const { groups } = queries;

export default {
  props: ["value"],
  apollo: { groups },
  data: () => ({
    rules: {
      required: (v) => !!v || "This field is required",
      email: (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      minOne: (v) => (v && v.length > 0) || "This field is required",
    },
    valid: null,
  }),
  computed: {
    form: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("change", value);
      },
    },
  },
};
</script>
