<template>
  <v-data-table
    dense
    :headers="headers"
    :items="users"
    item-key="name"
    class="elevation-0"
  >
    <template v-slot:item.groups="{ item }">
      <template v-for="(group, i) in item.groups">
        <v-chip small label v-if="i < 2" :key="i" class="mx-1">
          {{ group.name }}
        </v-chip>
      </template>
      <span class="grey--text" v-if="item.groups.length > 2">
        + {{ item.groups.length - 2 }} more</span
      >
    </template>
    <template v-slot:item.accountOwner="{ item }">
      <v-icon v-if="item.accountOwner" color="success" small
        >fa-check-circle</v-icon
      >
      <v-icon small color="error" v-else>fa-times-circle</v-icon>
    </template>
    <template v-slot:item.actions="{ item }">
      <delete-user-dialog
        :user="item"
        @delete="$emit('refresh')"
        :disabled="user.username === item.username || item.accountOwner"
      />
    </template>
  </v-data-table>
</template>

<script>
import DeleteUserDialog from "@/components/dialogs/DeleteUserDialog";
import { queries } from "@/gql";

const { user } = queries;

export default {
  components: { DeleteUserDialog },
  props: ["users"],
  apollo: { user },
  data: () => ({
    headers: [
      {
        text: "Email",
        value: "username",
      },
      {
        text: "Roles",
        value: "groups",
      },
      {
        text: "Account owner?",
        value: "accountOwner",
      },
      {
        text: "Actions",
        value: "actions",
      },
    ],
  }),
};
</script>
