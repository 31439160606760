<template>
  <v-row class="ma-4">
    <page-header />
    <v-col cols="12">
      <v-card tile>
        <table-header title="Users" v-if="account">
          <create-user-dialog
            @create="$apollo.queries.users.refetch()"
            :disabled="!account.canAddUsers"
          />
          <default-button
            @click="$apollo.queries.users.refetch()"
            icon="fa-sync"
            >Refresh</default-button
          >
        </table-header>
        <v-alert
          v-if="account && !account.canAddUsers"
          border="bottom"
          colored-border
          class="ma-4"
          type="warning"
          elevation="2"
        >
          You cannot add any more users at present - to increase your user
          limit, go to
          <router-link to="/billing">Upgrade your account</router-link>
        </v-alert>
        <user-table :users="users" @refresh="$apollo.queries.users.refetch()" />
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import PageHeader from "@/components/widgets/PageHeader";
import TableHeader from "@/components/widgets/TableHeader";
import CreateUserDialog from "@/components/dialogs/CreateUserDialog";
import UserTable from "@/components/tables/UserTable";
import { queries } from "@/gql";
import DefaultButton from "@/components/widgets/DefaultButton";

const { users, account } = queries;

export default {
  components: {
    DefaultButton,
    UserTable,
    CreateUserDialog,
    PageHeader,
    TableHeader,
  },
  apollo: { users, account },
};
</script>
