<template>
  <Dialog v-model="dialog" :max-width="600" title="Really delete user?">
    <v-snackbar color="error" v-model="error">{{ errorMessage }}</v-snackbar>
    <template v-slot:activator="{ on }">
      <default-button
        :on="on"
        color="error"
        icon="fa-trash"
        :disabled="disabled"
        >Delete</default-button
      >
    </template>
    <v-card-text>
      You are about to delete the user {{ user.username }}. This action cannot
      be undone. Are you sure you want to proceed?
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <default-button
        color="error"
        :loading="loading"
        @click="deleteUser"
        icon="fa-trash"
        >Delete</default-button
      >
    </v-card-actions>
  </Dialog>
</template>

<script>
import Dialog from "@/components/dialogs/Dialog";
import DefaultButton from "@/components/widgets/DefaultButton";

import { mutations } from "@/gql";

const { deleteUser: mutation } = mutations;

export default {
  components: { Dialog, DefaultButton },
  props: ["user", "disabled"],
  data: () => ({
    dialog: false,
    loading: false,
    error: false,
    errorMessage: null,
  }),
  methods: {
    deleteUser() {
      this.loading = true;
      this.$apollo
        .mutate({
          mutation,
          variables: {
            username: this.user.username,
          },
        })
        .catch((error) => {
          this.error = true;
          this.errorMessage = error.message.replace("GraphQL error: ", "");
          this.loading = false;
        })
        .then((result) => {
          if (result) {
            this.loading = false;
            this.$emit("delete");
            this.dialog = false;
          }
        });
    },
  },
};
</script>
