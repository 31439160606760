<template>
  <Dialog
    btn-color="success"
    btn-text="Create new user"
    btn-icon="fa-plus"
    :disabled="disabled"
    v-model="dialog"
    title="Create a new user"
  >
    <v-snackbar v-model="error" color="error">{{ errorMessage }}</v-snackbar>
    <v-card-text>
      To invite a user to join your Packagr team, simply add their email address
      below and click
      <span class="font-weight-bold">Create user</span>. The user will receive
      an email at the address you supply, asking them to set their password
    </v-card-text>
    <v-card-text>
      <v-form v-model="valid">
        <user-form v-model="form" />
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <default-button
        color="success"
        :disabled="!valid"
        :loading="loading"
        @click="createUser"
      >
        Create user
      </default-button>
    </v-card-actions>
  </Dialog>
</template>
<script>
import Dialog from "@/components/dialogs/Dialog";
import UserForm from "@/components/forms/UserForm";
import DefaultButton from "@/components/widgets/DefaultButton";
import { mutations } from "@/gql";

const { createUser: mutation } = mutations;

export default {
  props: ["disabled"],
  components: { DefaultButton, UserForm, Dialog },
  data: () => ({
    valid: null,
    form: {
      username: null,
      groupIds: [],
    },
    loading: false,
    dialog: false,
    error: false,
    errorMessage: null,
  }),
  methods: {
    async createUser() {
      this.loading = true;
      await this.$nextTick();
      this.$apollo
        .mutate({
          mutation,
          variables: this.form,
        })
        .then((result) => {
          if (result) {
            this.$emit("create");
            this.dialog = false;
            this.loading = false;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.error = true;
          this.errorMessage = error.message.replace("GraphQL error: ", "");
        });
    },
  },
};
</script>
